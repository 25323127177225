<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <input name="ID" [(ngModel)]="ContactService.formData.ID" type="hidden">
    <div class="row">
        <div class="col s12 m12 l10">
            <h5 class="card-title"><b>Liên hệ</b></h5>
        </div>
        <div class="col s12 m12 l2">
            <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                    class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
            <a (click)="onCopy()" title="Copy" class="btn-floating waves-effect waves-light cyan"><i
                    class="material-icons">filter_2</i></a>&nbsp;&nbsp;&nbsp;
            <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
                <i class="material-icons">close</i>
            </a>
        </div>
    </div>
    <div class="col s12 m12 l6">        
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="ParentID">Ngôn ngữ</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select
                style="padding-top:20px; padding-bottom:20px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="ParentID" [(ngModel)]="ContactService.formData.ParentID">
                <mat-option *ngFor="let item of CategoryLanguageService.list" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Tiêu đề</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Tiêu đề" name="Name" [(ngModel)]="ContactService.formData.Name" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SortOrder">Sắp xếp</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Sắp xếp" name="SortOrder" [(ngModel)]="ContactService.formData.SortOrder"
                    type="number">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="ContentHTML">Địa chỉ</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Địa chỉ" name="ContentHTML" [(ngModel)]="ContactService.formData.ContentHTML"
                    type="text">
            </div>
        </div>
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="Active">Kích hoạt</label>
            </div>
        </div>
        <div class="input-field col s6 m6 l8">
            <p>
                <label>
                    <input type="checkbox" class="filled-in" name="Active"
                        [(ngModel)]="ContactService.formData.Active" />
                    <span>Kích hoạt</span>
                </label>
            </p>
        </div>
    </div>
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Code">Email</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Email" name="Code" [(ngModel)]="ContactService.formData.Code" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Display">Di động</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Di động" name="Display" [(ngModel)]="ContactService.formData.Display" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Description">Cố định</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Cố định" name="Description" [(ngModel)]="ContactService.formData.Description"
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Status">Hotline (Không khoảng trắng)</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Hotline (Không khoảng trắng)" name="Status"
                    [(ngModel)]="ContactService.formData.Status" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Location">Messenger</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Messenger" name="Location" [(ngModel)]="ContactService.formData.Location"
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Note">Google map</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Google map" name="Note" [(ngModel)]="ContactService.formData.Note" type="text">
            </div>
        </div>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>