<div class="vertical-layout page-header-light vertical-menu-collapsible vertical-menu-nav-dark 2-columns mat-typography"
      data-open="click" data-menu="vertical-menu-nav-dark" data-col="2-column">
      <header class="page-topbar" id="header">
            <div class="navbar navbar-fixed">
                  <nav class="navbar-main navbar-color nav-collapsible sideNav-lock navbar-light">
                        <div class="nav-wrapper">
                              <ul class="navbar-list right">
                                    <li><a class="waves-effect waves-block waves-light profile-button"
                                                href="javascript:void(0);" data-target="profile-dropdown"><span
                                                      class="avatar-status avatar-online"><img
                                                            src="https://cms.ctacorporation.vn/image/logo.png"
                                                            alt="avatar"><i></i></span></a></li>
                                    <li><a class="waves-effect waves-block waves-light sidenav-trigger" href="#"
                                                data-target="slide-out-right"><i
                                                      class="material-icons">format_indent_increase</i></a></li>
                              </ul>
                              <ul class="dropdown-content" id="translation-dropdown">
                              </ul>
                              <ul class="dropdown-content" id="notifications-dropdown">
                              </ul>
                              <ul class="dropdown-content" id="profile-dropdown">
                              </ul>
                        </div>
                        <nav class="display-none search-sm">
                              <div class="nav-wrapper">
                              </div>
                        </nav>
                  </nav>
            </div>
      </header>
      <aside class="sidenav-main nav-expanded nav-lock nav-collapsible sidenav-dark sidenav-active-rounded">
            <div class="brand-sidebar">
                  <h1 class="logo-wrapper"><a class="brand-logo darken-1" href="#" style="color:#009f3c;"><span
                                    class="logo-text hide-on-med-and-down" style="color:#009f3c;">CTA Design & Build</span></a><a
                              class="navbar-toggler" href="#"><i class="material-icons">radio_button_checked</i></a>
                  </h1>
            </div>
            <ul class="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow"
                  id="slide-out" data-menu="menu-navigation" data-collapsible="accordion">     
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}About"><i
                        class="material-icons">account_box</i><span class="menu-title" data-i18n="">Giới thiệu</span></a>
                  </li>          
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Award"><i
                        class="material-icons">card_giftcard</i><span class="menu-title" data-i18n="">Giải thưởng</span></a>
                  </li>           
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Project"><i
                        class="material-icons">apps</i><span class="menu-title" data-i18n="">Dự án</span></a>
                  </li>
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}News"><i
                        class="material-icons">assignment</i><span class="menu-title" data-i18n="">Tin tức</span></a>
                  </li>                     
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Service"><i
                        class="material-icons">dashboard</i><span class="menu-title" data-i18n="">Dịch vụ</span></a>
                  </li>    
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Ideas"><i
                        class="material-icons">favorite_border</i><span class="menu-title" data-i18n="">Ý tưởng</span></a>
                  </li>    
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Team"><i
                        class="material-icons">face</i><span class="menu-title" data-i18n="">Đội ngũ</span></a>
                  </li>    
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Career"><i
                        class="material-icons">flag</i><span class="menu-title" data-i18n="">Tuyển dụng</span></a>
                  </li> 
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Banner"><i
                        class="material-icons">filter_vintage</i><span class="menu-title" data-i18n="">Banner</span></a>
                  </li>    
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Feedback"><i
                        class="material-icons">chat</i><span class="menu-title" data-i18n="">Phản hồi</span></a>
                  </li> 
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Contact"><i
                        class="material-icons">public</i><span class="menu-title" data-i18n="">Liên hệ</span></a>
                  </li>     
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Newsletter"><i
                        class="material-icons">contact_mail</i><span class="menu-title" data-i18n="">Đăng ký nhận tin</span></a>
                  </li>  
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Candidate"><i
                        class="material-icons">accessibility</i><span class="menu-title" data-i18n="">Ứng viên</span></a>
                  </li>  
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Menu"><i
                        class="material-icons">dehaze</i><span class="menu-title" data-i18n="">Menu</span></a>
                  </li>  
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Email"><i
                        class="material-icons">email</i><span class="menu-title" data-i18n="">Email</span></a>
                  </li>  
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Quote"><i
                        class="material-icons">comment</i><span class="menu-title" data-i18n="">Trích dẫn</span></a>
                  </li>  
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}SocialNetwork"><i
                        class="material-icons">filter_vintage</i><span class="menu-title" data-i18n="">Mạng xã hội</span></a>
                  </li>  
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}CategoryIdeas"><i
                        class="material-icons">create_new_folder</i><span class="menu-title" data-i18n="">Loại ý tưởng</span></a>
                  </li>  
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}CategoryLanguage"><i
                                    class="material-icons">language</i><span class="menu-title" data-i18n="">Ngôn ngữ</span></a>
                  </li>    
                  <li class="bold"><a class="waves-effect waves-cyan " href="{{domainName}}Membership"><i
                        class="material-icons">directions_walk</i><span class="menu-title" data-i18n="">Thành viên</span></a>
                  </li>                
            </ul>
            <div class="navigation-background"></div><a
                  class="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-effect waves-light hide-on-large-only"
                  href="#" data-target="slide-out"><i class="material-icons">menu</i></a>
      </aside>
      <div id="main">
            <router-outlet></router-outlet>
      </div>
</div>