<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <input name="ID" [(ngModel)]="MenuService.formData.ID" type="hidden">
    <input name="FileName" [(ngModel)]="MenuService.formData.FileName" type="hidden">
    <div class="row">
        <div class="col s12 m12 l10">
            <h5 class="card-title"><b>Menu</b></h5>
        </div>
        <div class="col s12 m12 l2">
            <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                    class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
            <a (click)="onCopy()" title="Copy" class="btn-floating waves-effect waves-light cyan"><i
                    class="material-icons">filter_2</i></a>&nbsp;&nbsp;&nbsp;
            <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
                <i class="material-icons">close</i>
            </a>
        </div>
    </div>
    <div class="col s12 m12 l6">
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="ParentID">Ngôn ngữ</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select
                style="padding-top:20px; padding-bottom:20px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="ParentID" [(ngModel)]="MenuService.formData.ParentID">
                <mat-option *ngFor="let item of CategoryLanguageService.list" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Menu</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Menu" name="Name" [(ngModel)]="MenuService.formData.Name" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Display">URL</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="URL" name="Display" [(ngModel)]="MenuService.formData.Display" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Description">Điều hướng</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Điều hướng" name="Description" [(ngModel)]="MenuService.formData.Description"
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="ContentHTML">Tiêu đề</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Tiêu đề" name="ContentHTML" [(ngModel)]="MenuService.formData.ContentHTML"
                    type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Note">URL</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="URL" name="Note" [(ngModel)]="MenuService.formData.Note" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SortOrder">Sắp xếp</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Sắp xếp" name="SortOrder" [(ngModel)]="MenuService.formData.SortOrder"
                    type="number">
            </div>
        </div>
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="Active">Kích hoạt</label>
            </div>
        </div>
        <div class="input-field col s6 m6 l8">
            <p>
                <label>
                    <input type="checkbox" class="filled-in" name="Active"
                        [(ngModel)]="MenuService.formData.Active" />
                    <span>Kích hoạt</span>
                </label>
            </p>
        </div>
    </div>
    <div class="col s12 m12 l6">
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="FileName">Hình ảnh</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input type="file" multiple (change)="changeImage($event.target.files)">
            </div>
            <div class="input-field">
                <a target="_blank" href="{{MenuService.formData.FileName}}" *ngIf="MenuService.formData.FileName"
                    title="{{MenuService.formData.FileName}}">
                    <img class="responsive-img" width="50%" title="{{MenuService.formData.FileName}}"
                        alt="{{MenuService.formData.FileName}}" [src]="MenuService.formData.FileName"></a>
            </div>
        </div>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>