<div class="row">
    <div id="breadcrumbs-wrapper" data-image="https://cms.ctacorporation.vn/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Đội ngũ <span
                            *ngIf="TeamService.list">({{TeamService.list.length}} items)</span></h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CMS</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Đội ngũ
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div class="container">
            <div class="section section-data-tables">
                <div class="card">
                    <div class="card-content">
                        <div class="col s12 m12 l3">
                            <mat-select
                                style="padding-top:14px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                name="parentID" class="browser-default" [(ngModel)]="parentID">
                                <mat-option *ngFor="let item of CategoryLanguageService.list" [value]="item.ID">
                                    {{item.Name}}
                                </mat-option>
                            </mat-select>
                        </div>
                        <div class="col s12 m12 l6">
                            <input matInput [(ngModel)]="searchString" placeholder="Tìm ..."
                                class="mat-input-element mat-form-field-autofill-control" autocomplete="off"
                                (keyup.enter)="onSearch()">
                        </div>
                        <div class="col s12 m12 l3">
                            <a title="Tìm" (click)="onSearch()"
                                class="btn-floating waves-effect waves-light green darken-1"><i
                                    class="material-icons">search</i></a>&nbsp;&nbsp;&nbsp;
                            <a title="Thêm mới" target="_blank" routerLink="{{detailURL}}/0"
                                class="btn-floating waves-effect waves-light green darken-1"><i
                                    class="material-icons">add</i></a>
                        </div>
                        <div class="KhungOverflow">
                            <mat-table [dataSource]="dataSource" matSort>
                                <!-- <ng-container matColumnDef="ID">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>ID
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{element.ID}}</mat-cell>
                                </ng-container> -->
                                <ng-container matColumnDef="FileName">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Hình ảnh
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">
                                        <a target="_blank" href="{{element.FileName}}" *ngIf="element.FileName"
                                            title="{{element.FileName}}">
                                            <img class="responsive-img" width="50%" title="{{element.FileName}}"
                                                alt="{{element.FileName}}" [src]="element.FileName"></a>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Name">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element"><a title="Chi tiết" target="_blank"
                                            routerLink="{{detailURL}}/{{element.ID}}"><b>{{element.Name}}</b></a></mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="SortOrder">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{element.SortOrder | number:
                                        '1.0-0'}}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="IsHomePage">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Trang chủ
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{element.IsHomePage}}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Active">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>Kích hoạt
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element">{{element.Active}}</mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="Save">
                                    <mat-header-cell *matHeaderCellDef mat-sort-header>
                                    </mat-header-cell>
                                    <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                        <a title="Website" target="_blank"
                                            href="{{liveURL}}Team/{{element.Code}}-{{element.ID}}.html"
                                            class="btn-floating waves-effect waves-light green darken-1"><i
                                                class="material-icons">laptop</i></a>&nbsp;&nbsp;&nbsp;
                                        <a style="padding-left: 8px;" title="Xóa"
                                            class="btn-floating waves-effect waves-light red" *ngIf="element.ID>0"
                                            (click)="onDelete(element)"><i class="material-icons">delete_outline</i></a>
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="loading">
                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                        Đang tải dữ liệu...
                                    </mat-footer-cell>
                                </ng-container>
                                <ng-container matColumnDef="noData">
                                    <mat-footer-cell *matFooterCellDef colspan="4">
                                        Chưa có dữ liệu.
                                    </mat-footer-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="displayColumns"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: displayColumns;"></mat-row>
                                <mat-footer-row *matFooterRowDef="['loading']"
                                    [ngClass]="{'hide':dataSource!=null}"></mat-footer-row>
                                <mat-footer-row *matFooterRowDef="['noData']"
                                    [ngClass]="{'hide':!(dataSource!=null && dataSource.data.length==0)}">
                                </mat-footer-row>
                            </mat-table>
                            <mat-paginator [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                                [showFirstLastButtons]></mat-paginator>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-loading *ngIf="isShowLoading"></app-loading>