<form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
    <input name="ID" [(ngModel)]="AboutService.formData.ID" type="hidden">
    <input name="FileName" [(ngModel)]="AboutService.formData.FileName" type="hidden">
    <div class="col s12 m12 l5">
        <div class="col s12 m12 l9">
            <h5 class="card-title"><b>Giới thiệu</b></h5>
        </div>
        <div class="col s12 m12 l3">
            <button title="Lưu thay đổi" type="submit" class="btn-floating waves-effect waves-light cyan"><i
                    class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
            <a title="Đóng" class="btn-floating waves-effect waves-light purple lightrn-1" (click)="onClose()">
                <i class="material-icons">close</i>
            </a>
        </div>
        <div class="col s6 m6 l4">
            <div class="input-field">
                <label for="ParentID">Ngôn ngữ</label>
            </div>
        </div>
        <div class="col s6 m6 l8">
            <mat-select
                style="padding-top:10px; padding-bottom:10px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                name="ParentID" [(ngModel)]="AboutService.formData.ParentID">
                <mat-option *ngFor="let item of CategoryLanguageService.list" [value]="item.ID">
                    {{item.Name}}
                </mat-option>
            </mat-select>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="Name">Tiêu đề</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Tiêu đề" name="Name" [(ngModel)]="AboutService.formData.Name" type="text">
            </div>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="SortOrder">Sắp xếp</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input placeholder="Sắp xếp" name="SortOrder" [(ngModel)]="AboutService.formData.SortOrder"
                    type="number">
            </div>
        </div>
        <div class="col s4 m4 l4"></div>
        <div class="input-field col s4 m4 l4">
            <p>
                <label>
                    <input type="checkbox" class="filled-in" name="IsHomePage"
                        [(ngModel)]="AboutService.formData.IsHomePage" />
                    <span>Trang chủ</span>
                </label>
            </p>
        </div>
        <div class="input-field col s4 m4 l4">
            <p>
                <label>
                    <input type="checkbox" class="filled-in" name="Active" [(ngModel)]="AboutService.formData.Active" />
                    <span>Kích hoạt</span>
                </label>
            </p>
        </div>
        <div class="col s4 m4 l4">
            <div class="input-field">
                <label for="FileName">Hình ảnh</label>
            </div>
        </div>
        <div class="col s8 m8 l8">
            <div class="input-field">
                <input type="file" multiple (change)="changeImage($event.target.files)">
            </div>
            <div class="input-field">
                <a target="_blank" href="{{AboutService.formData.FileName}}" *ngIf="AboutService.formData.FileName"
                    title="{{AboutService.formData.FileName}}">
                    <img class="responsive-img" width="50%" title="{{AboutService.formData.FileName}}"
                        alt="{{AboutService.formData.FileName}}" [src]="AboutService.formData.FileName"></a>
            </div>
        </div>
    </div>
    <div class="col s12 m12 l7">
        <ck-editor [config]="{'height': 500}" name="ContentHTML" [(ngModel)]="AboutService.formData.ContentHTML"
            skin="moono-lisa" language="en" [fullPage]="true"></ck-editor>
    </div>
</form>
<app-loading *ngIf="isShowLoading"></app-loading>