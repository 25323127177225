<div class="row">
    <div id="breadcrumbs-wrapper" data-image="https://cms.ctacorporation.vn/app-assets/images/gallery/breadcrumb-bg.jpg"
        style="color: #ffffff; background: rgb(40,70,99); background: linear-gradient(90deg, rgba(40,70,99,1) 0%, rgba(62,110,159,1) 35%, rgba(106,179,245,1) 100%);">
        <div class="container">
            <div class="row">
                <div class="col s12 m6 l6">
                    <h5 class="breadcrumbs-title mt-0 mb-0" style="color: #ffffff;">Giải thưởng <span
                            *ngIf="AwardService.list">({{AwardService.list.length}} items)</span></h5>
                </div>
                <div class="col s12 m6 l6 right-align-md">
                    <ol class="breadcrumbs mb-0">
                        <li class="breadcrumb-item"><a href="#" style="color: #ffffff;">CMS</a>
                        </li>
                        <li class="breadcrumb-item active" style="color: #ffffff;">Giải thưởng
                        </li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
    <div class="col s12">
        <div id="input-fields" class="card card-tabs">
            <div class="card-content">
                <div class="card-title">
                    <div class="row">
                        <div class="col s6 m6 l8">
                        </div>
                        <div class="col s6 m6 l4">
                            <ul class="tabs">
                                <li class="tab col s6 p-0"><a class="active p-0" href="#Detail">Thông tin</a></li>
                                <li class="tab col s6 p-0"><a class="p-0" href="#Media">Media</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div id="Detail">
                    <form autocomplete="off" class="row" #form="ngForm" (submit)="onSubmit(form)">
                        <input name="ID" [(ngModel)]="AwardService.formData.ID" type="hidden">
                        <input name="FileName" [(ngModel)]="AwardService.formData.FileName" type="hidden">
                        <div class="col s12 m12 l5">
                            <div class="col s12 m12 l12">
                                <a title="Danh sách" href="javascript:window.open('','_self').close();"
                                    class="btn-floating waves-effect waves-light amber darken-4"><i
                                        class="material-icons">arrow_back</i></a>&nbsp;&nbsp;&nbsp;
                                <button title="Lưu thay đổi" type="submit"
                                    class="btn-floating waves-effect waves-light cyan"><i
                                        class="material-icons">save</i></button>&nbsp;&nbsp;&nbsp;
                                <a (click)="onCopy()" title="Copy" class="btn-floating waves-effect waves-light cyan"><i
                                        class="material-icons">filter_2</i></a>&nbsp;&nbsp;&nbsp;
                                <a title="Website" target="_blank"
                                    href="{{liveURL}}Award/{{AwardService.formData.Code}}-{{AwardService.formData.ID}}.html"
                                    class="btn-floating waves-effect waves-light green darken-1"><i
                                        class="material-icons">laptop</i></a>&nbsp;&nbsp;&nbsp;
                                <a title="Thêm mới" routerLink="{{detailURL}}/0"
                                    class="btn-floating waves-effect waves-light green darken-1"><i
                                        class="material-icons">add</i></a>
                            </div>
                            <div class="col s6 m6 l4">
                                <div class="input-field">
                                    <label for="ParentID">Ngôn ngữ</label>
                                </div>
                            </div>
                            <div class="col s6 m6 l8">
                                <mat-select
                                    style="padding-top:20px; padding-bottom:20px; border-bottom-color: #000000; border-bottom-width: 1px; border-bottom-style: solid;"
                                    name="ParentID" [(ngModel)]="AwardService.formData.ParentID">
                                    <mat-option *ngFor="let item of CategoryLanguageService.list" [value]="item.ID">
                                        {{item.Name}}
                                    </mat-option>
                                </mat-select>
                            </div>
                            <div class="col s4 m4 l4">
                                <div class="input-field">
                                    <label for="Name">Tiêu đề</label>
                                </div>
                            </div>
                            <div class="col s8 m8 l8">
                                <div class="input-field">
                                    <input placeholder="Tiêu đề" name="Name" [(ngModel)]="AwardService.formData.Name"
                                        type="text">
                                </div>
                            </div>
                            <div class="col s4 m4 l4">
                                <div class="input-field">
                                    <label for="Description">Mô tả</label>
                                </div>
                            </div>
                            <div class="col s8 m8 l8">
                                <div class="input-field">
                                    <input placeholder="Mô tả" name="Description"
                                        [(ngModel)]="AwardService.formData.Description" type="text">
                                </div>
                            </div>
                            <div class="col s4 m4 l4">
                                <div class="input-field">
                                    <label for="Code">Code</label>
                                </div>
                            </div>
                            <div class="col s8 m8 l8">
                                <div class="input-field">
                                    <input placeholder="Code" name="Code" [(ngModel)]="AwardService.formData.Code"
                                        type="text">
                                </div>
                            </div>
                            <div class="col s4 m4 l4">
                                <div class="input-field">
                                    <label for="Note">Ghi chú</label>
                                </div>
                            </div>
                            <div class="col s8 m8 l8">
                                <div class="input-field">
                                    <input placeholder="Ghi chú" name="Note" [(ngModel)]="AwardService.formData.Note"
                                        type="text">
                                </div>
                            </div>
                            <div class="col s4 m4 l4">
                                <div class="input-field">
                                    <label for="SortOrder">Sắp xếp</label>
                                </div>
                            </div>
                            <div class="col s8 m8 l8">
                                <div class="input-field">
                                    <input placeholder="Sắp xếp" name="SortOrder"
                                        [(ngModel)]="AwardService.formData.SortOrder" type="number">
                                </div>
                            </div>
                            <!-- <div class="col s4 m4 l4">
                                <div class="input-field">
                                    <label for="Status">QC: Tình trạng</label>
                                </div>
                            </div>
                            <div class="col s8 m8 l8">
                                <div class="input-field">
                                    <input placeholder="Quy cách: Tình trạng" name="Status"
                                        [(ngModel)]="AwardService.formData.Status" type="text">
                                </div>
                            </div>
                            <div class="col s4 m4 l4">
                                <div class="input-field">
                                    <label for="Location">QC: Vị trí</label>
                                </div>
                            </div>
                            <div class="col s8 m8 l8">
                                <div class="input-field">
                                    <input placeholder="Quy cách: Vị trí" name="Location"
                                        [(ngModel)]="AwardService.formData.Location" type="text">
                                </div>
                            </div>     
                            <div class="col s4 m4 l4">
                                <div class="input-field">
                                    <label for="Recognize">QC: Công nhận</label>
                                </div>
                            </div>
                            <div class="col s8 m8 l8">
                                <div class="input-field">
                                    <input placeholder="Quy cách: Công nhận" name="Recognize"
                                        [(ngModel)]="AwardService.formData.Recognize" type="text">
                                </div>
                            </div>                            
                            <div class="col s4 m4 l4">
                                <div class="input-field">
                                    <label for="Area">QC: Khu vực</label>
                                </div>
                            </div>
                            <div class="col s8 m8 l8">
                                <div class="input-field">
                                    <input placeholder="Quy cách: Khu vực" name="Area"
                                        [(ngModel)]="AwardService.formData.Area" type="text">
                                </div>
                            </div>
                            <div class="col s4 m4 l4">
                                <div class="input-field">
                                    <label for="DateComplete">QC: Ngày hoàn thành</label>
                                </div>
                            </div>
                            <div class="col s8 m8 l8">
                                <div class="input-field">
                                    <input placeholder="Quy cách: Ngày hoàn thành" name="DateComplete"
                                        [(ngModel)]="AwardService.formData.DateComplete" type="text">
                                </div>
                            </div> -->
                            <div class="input-field col s4 m4 l4">
                                <p>
                                    <label>
                                        <input type="checkbox" class="filled-in" name="IsShow"
                                            [(ngModel)]="AwardService.formData.IsShow" />
                                        <span>Quy cách (QC)</span>
                                    </label>
                                </p>
                            </div>
                            <div class="input-field col s4 m4 l4">
                                <p>
                                    <label>
                                        <input type="checkbox" class="filled-in" name="IsHomePage"
                                            [(ngModel)]="AwardService.formData.IsHomePage" />
                                        <span>Trang chủ</span>
                                    </label>
                                </p>
                            </div>
                            <div class="input-field col s4 m4 l4">
                                <p>
                                    <label>
                                        <input type="checkbox" class="filled-in" name="Active"
                                            [(ngModel)]="AwardService.formData.Active" />
                                        <span>Kích hoạt</span>
                                    </label>
                                </p>
                            </div>
                            <div class="col s4 m4 l4">
                                <div class="input-field">
                                    <label for="FileName">Hình ảnh</label>
                                </div>
                            </div>
                            <div class="col s8 m8 l8">
                                <div class="input-field">
                                    <input type="file" multiple (change)="changeImage($event.target.files)">
                                </div>
                                <div class="input-field">
                                    <a target="_blank" href="{{AwardService.formData.FileName}}"
                                        *ngIf="AwardService.formData.FileName"
                                        title="{{AwardService.formData.FileName}}">
                                        <img class="responsive-img" width="50%"
                                            title="{{AwardService.formData.FileName}}"
                                            alt="{{AwardService.formData.FileName}}"
                                            [src]="AwardService.formData.FileName"></a>
                                </div>
                            </div>
                        </div>
                        <div class="col s12 m12 l7">
                            <ck-editor [config]="{'height': 800}" name="ContentHTML"
                                [(ngModel)]="AwardService.formData.ContentHTML" skin="moono-lisa" language="en"
                                [fullPage]="true"></ck-editor>
                        </div>
                    </form>
                </div>
                <div id="Media">
                    <div class="col s4 m4 l4">
                        <div class="input-field">
                            <input type="file" multiple (change)="changeFiles($event.target.files)">
                        </div>
                    </div>
                    <div class="col s4 m4 l4">
                        <a class="btn-floating waves-effect waves-light cyan" *ngIf="AwardService.formData.ID>0"
                            (click)="onAddFiles()"><i class="material-icons">cloud_upload</i></a>
                    </div>
                    <div class="KhungOverflow">
                        <mat-table [dataSource]="dataSourceFile" matSort>
                            <ng-container matColumnDef="Note">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Media
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <a target="_blank" title="{{element.FileName}}" *ngIf="element.FileName"
                                        href="{{element.FileName}}"><img width="50%" class="responsive-img"
                                            title="{{element.FileName}}" alt="{{element.FileName}}"
                                            [src]="element.FileName"></a>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="FileName">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Đường dẫn
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                    <input placeholder="Đường dẫn" name="FileName{{element.ID}}"
                                        [(ngModel)]="element.FileName" type="text">
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Name">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Tiêu đề
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                    <input placeholder="Tiêu đề" name="Name{{element.ID}}" [(ngModel)]="element.Name"
                                        type="text">
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="SortOrder">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>Sắp xếp
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element" style="margin-right: 10px;">
                                    <input placeholder="0" name="SortOrder{{element.ID}}" style="text-align: right;"
                                        [(ngModel)]="element.SortOrder" type="number">
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="Save">
                                <mat-header-cell *matHeaderCellDef mat-sort-header>
                                </mat-header-cell>
                                <mat-cell *matCellDef="let element">
                                    <a title="Lưu thay đổi" (click)="onSaveFile(element)"
                                        class="btn-floating waves-effect waves-light cyan"><i
                                            class="material-icons">save</i></a>&nbsp;&nbsp;&nbsp;
                                    <a style="padding-left: 8px;" (click)="onDeleteFile(element)" *ngIf="element.ID>0"
                                        class="btn-floating waves-effect waves-light red"><i
                                            class="material-icons">delete_outline</i></a>
                                </mat-cell>
                            </ng-container>
                            <ng-container matColumnDef="loading">
                                <mat-footer-cell *matFooterCellDef colspan="4">
                                    Đang tải dữ liệu...
                                </mat-footer-cell>
                            </ng-container>
                            <ng-container matColumnDef="noData">
                                <mat-footer-cell *matFooterCellDef colspan="4">
                                    Chưa có dữ liệu.
                                </mat-footer-cell>
                            </ng-container>
                            <mat-header-row *matHeaderRowDef="displayColumnsFile"></mat-header-row>
                            <mat-row *matRowDef="let row; columns: displayColumnsFile;"></mat-row>
                            <mat-footer-row *matFooterRowDef="['loading']"
                                [ngClass]="{'hide':dataSourceFile!=null}"></mat-footer-row>
                            <mat-footer-row *matFooterRowDef="['noData']"
                                [ngClass]="{'hide':!(dataSourceFile!=null && dataSourceFile.data.length==0)}">
                            </mat-footer-row>
                        </mat-table>
                        <mat-paginator [pageSizeOptions]="[10,20,50,100]" [pageSize]="5"
                            [showFirstLastButtons]></mat-paginator>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<app-loading *ngIf="isShowLoading"></app-loading>